//----------------------------------*/
// VENDOR
//----------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700|Roboto+Slab:400,700&display=swap');

//uikit
$global-font-family: 'Raleway',
sans-serif;
$global-color: #ED3237;
$navbar-nav-item-active-color: #ED3237;
$navbar-dropdown-nav-header-color: #ED3237;
$navbar-dropdown-nav-item-active-color: #ED3237;
$navbar-nav-item-text-transform: none;
$navbar-nav-item-color: #ffffff;
$navbar-nav-item-height: 40px;
$container-max-width: 1170px;
$card-default-color: #000;
$card-default-background: #fff;
$section-muted-background: #F5F4F7;
$section-large-padding-vertical-m: 100px;
$internal-form-select-image: '../img/merino-home/down-arrow.svg';
$base-selection-background: #616161;
$base-link-color: #ED3237;

@import '../components//datepicker/datepicker.scss';
@import '../components/uikit/scss/variables.scss';
@import '../components/uikit/scss/variables-theme.scss';
@import '../components/uikit/scss/mixins.scss';
@import '../components/uikit/scss/mixins-theme.scss';
@import '../components/uikit/scss/uikit-theme.scss';
@import '../components/uikit/scss/uikit.scss';

//External
@import '../components/selectize/selectize.scss';
@import '../components/slick/slick.scss';
@import '../components/slick/slick-theme.scss';
@import '../components/animatecss/animate.scss';
@import '../components/headspace/headspace.scss';